


































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { apiBuilder, fanpageUrl } from '../config';
import axios from 'axios';
import { namespace } from 'vuex-class';
import AccountShowModal from './AccountShowModal.vue';
import * as dayjs from 'dayjs';

import { UserProfileInterface } from '../interfaces';

const auth = namespace('auth');

interface PriceObject {
	price: string;
	text: string;
	value: string;
}

@Component({
	components: {
		AccountShowModal,
	},
})
export default class BuyNowModal extends Vue {
	@Prop() readonly value!: boolean;
	@Prop() readonly product!: any; //eslint-disable-line

	//STATE

	private rules = {
		required: (value: string | number) => !!value || 'Không Được Trống.',
		ticked: (value: boolean) => !!value || 'Bạn chưa đồng ý với điều khoản!',
	};

	private priceList: Array<PriceObject> = [];

	private buyNowForm = {
		form: null,
		period: null,
		discount: '',
		accredit: false,
		profileCount: 1,
		username: '',
		password: '',
		totalMoney: '0',
		policy: false,
	};

	private voucherGroup = {
		formDisable: false,
		btnDelete: false,
	};

	private discount: string | number = 0;

	@auth.State
	private token!: string;

	@auth.State
	private username!: string;

	private userProfile!: UserProfileInterface;

	private accountShow = {
		modal: false,
		data: {},
	};

	// COMPUTED

	get dialog(): boolean {
		if (this.value && !this.userProfile) {
			this.getUserProfile();
		}

		return this.value;
	}

	set dialog(value) {
		this.$emit('input', value);
	}

	//METHOD

	private getPeriodName(id: number | string | null): string {
		let periodName = '';
		for (let i = 0; i < this.priceList.length; i++) {
			if (this.priceList[i].value == id) {
				periodName = this.priceList[i].text;
			}
		}

		return periodName;
	}

	private getPrice(id: string | number): void {
		const api = apiBuilder('product/listproductbyproducttype.php');

		const param = {
			params: {
				idproducttype: id,
			},
		};

		axios
			.post(api, null, param)
			.then((res) => {
				// console.log(res.data)

				if (res.data.error != '750') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi!',
						text: 'Lỗi Máy Chủ!',
					});
				}

				const data = res.data.data;

				// eslint-disable-next-line
				const a = data.map((item: any) => {
					return {
						text: item.rent_period + ' Tháng',
						value: item.id,
						price: item.price,
					};
				});

				this.priceList = a;
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi!',
					text: e,
				});
			});
	}

	private totalMoney(): void {
		let periodPrice: string | number = 0;
		const profileCount: string | number = this.buyNowForm.profileCount | 0;
		const priceList = this.priceList;

		for (let i = 0; i < priceList.length; i++) {
			if (priceList[i].value == this.buyNowForm.period) {
				periodPrice = priceList[i].price;
			}
		}

		let acCredit: string | number | undefined = 0;

		if (this.buyNowForm.accredit) {
			acCredit = this.userProfile.accredited;
		}

		let total = 0;

		total = Number(periodPrice) * Number(profileCount) - Number(this.discount) - Number(acCredit);

		if (total < 0) total = 0;

		this.buyNowForm.totalMoney = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(total);
		// console.log(total)
	}

	private getUserProfile(): void {
		const api = apiBuilder('profile/profileinfo.php');

		const param = {
			params: {
				token: this.token || 'a',
			},
		};

		axios
			.post(api, null, param)
			.then((res) => {
				// console.log(res.data)
				const error = res.data.error;

				if (error == '302') {
					sessionStorage.setItem('redirectPath', window.location.pathname);

					this.$router.replace({
						name: 'Login',
					});
				} else if (error != '301') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Plesae Reload',
					});
				}

				this.userProfile = res.data;
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});
	}

	private checkVoucher() {
		if (this.buyNowForm.discount == '' || this.buyNowForm.discount == undefined) {
			return this.$swal({
				icon: 'error',
				title: 'Lỗi!',
				text: 'Mã Voucher Không Được Trống !',
			});
		}

		const apiUrl = apiBuilder('transaction/vouchervalue.php');
		const param = {
			params: {
				voucher: this.buyNowForm.discount,
			},
		};

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				if (res.data.error == '531') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi!',
						text: 'Voucher Không Hợp Lệ !',
					});
				} else if (res.data.error != '530') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi!',
						text: 'Server Error ! Please Reload !',
					});
				}

				this.discount = res.data.voucher_price;
				this.voucherGroup.formDisable = true;
				this.voucherGroup.btnDelete = true;

				this.totalMoney();

				return this.$swal({
					icon: 'success',
					title: 'Thành Công !',
					text: 'Thêm Voucher Thành Công !',
				});
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi!',
					text: e,
				});
			});
	}

	private deleteVoucher() {
		this.discount = 0;
		this.buyNowForm.discount = '';
		this.voucherGroup.formDisable = false;
		this.voucherGroup.btnDelete = false;

		this.totalMoney();

		return this.$swal({
			icon: 'success',
			title: 'Thành Công!',
			text: 'Xoá Voucher Thành Công !',
		});
	}

	private rentNow(): void {
		const a = this.$refs.form as Vue & { validate: () => boolean; reset: () => boolean };

		if (a.validate()) {
			this.$swal({
				icon: 'question',
				title: 'Xác Nhận Đơn Hàng',
				html:
					`<div>Tài Khoản: <b>${this.product.product_name}</b><br>` +
					`Thời Hạn: <strong>${this.getPeriodName(this.buyNowForm.period)}</strong><br>` +
					`Số Profile: <strong>${this.buyNowForm.profileCount}</strong><br>` +
					`Mã Giảm Giá: <strong>${this.buyNowForm.discount || 'Không Có'}</strong><br>` +
					`Số Tiền Được Giảm: <strong> ${'-' + this.discount || 0} </strong><br>` +
					`Sử Dụng Tiền Tích Luỹ: <strong>${this.buyNowForm.accredit ? 'Có' : 'Không'}</strong><br>` +
					`<p style="color: red">Tổng Tiền: <strong>${this.buyNowForm.totalMoney}</strong></p></div>`,
				showCancelButton: true,
				showConfirmButton: true,
				cancelButtonText: 'Huỷ Bỏ',
				confirmButtonText: 'Chắc Rồi !',
				reverseButtons: true,
				focusConfirm: true,
			})
				.then((x) => {
					if (x.isConfirmed) {
						const apiUrl = apiBuilder('product/buygeneralproduct.php');

						const param = {
							params: {
								productid: this.buyNowForm.period,
								username: this.username,
								token: this.token,
								voucher: this.buyNowForm.discount || '',
								isuseaccredited: this.buyNowForm.accredit ? '1' : '0',
								numberofslot: this.buyNowForm.profileCount,
							},
						};

						axios.post(apiUrl, null, param).then((res) => {
							// console.log(res.data)

							const error = res.data.error;

							if (error == '731') {
								sessionStorage.setItem('redirectPath', window.location.pathname);

								return this.$router.replace({
									name: 'Login',
								});
							} else if (error == '733') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Sản Phẩm Không Tồn Tại !',
								});
							} else if (error == '732') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Mã Tài Khoảng Phải Là Số !',
								});
							} else if (error == '734') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									html: 'Hết Hàng ! <br> Vui lòng nhắn tin cho Fanpage',
									showConfirmButton: true,
									showCancelButton: true,
									confirmButtonText: 'Đến Fanpage',
									cancelButtonText: 'Đóng',
									reverseButtons: true,
								}).then((a) => {
									if (a.isConfirmed) {
										window.location.href = fanpageUrl;
									}
								});
							} else if (error == '735') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Số Dư Không Đủ !',
								});
							} else if (error == '736') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Voucher Không Hợp Lệ !',
								});
							} else if (error != '730') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Server Error ! Please Reload',
								});
							} else {
								const account = res.data.data.map((x: any) => {
									return {
										account: x.account,
										password: x.password,
										slot: x.slot,
										expiredAt: dayjs.unix(x.expired_at).format('DD/MM/YYYY HH:mm:ss'),
									};
								});

								const newBalance = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(res.data.newbalance);
								const newaccredit = new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(res.data.newaccredit);

								this.accountShow.data = {
									newBalance: newBalance,
									newAcCredit: newaccredit,
									accountData: account,
								};

								this.$emit('input', false);

								this.accountShow.modal = true;

								a.reset();
							}
						});
					}
				})
				.catch((e) => {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: e,
					});
				});
		}
	}

	private regNow(): void {
		const a = this.$refs.form as Vue & { validate: () => boolean; reset: () => boolean };

		if (a.validate()) {
			this.$swal({
				icon: 'question',
				title: 'Xác Nhận Đơn Hàng',
				html:
					`<div>Tài Khoản: <b>${this.product.product_name}</b><br>` +
					`Thời Hạn: <strong>${this.getPeriodName(this.buyNowForm.period)}</strong><br>` +
					`Tên Đăng Nhập: <strong>${this.buyNowForm.username}</strong><br>` +
					`Mật Khẩu: <strong>${this.buyNowForm.password}</strong><br>` +
					`Mã Giảm Giá: <strong>${this.buyNowForm.discount || 'Không Có'}</strong><br>` +
					`Số Tiền Được Giảm: <strong> ${'-' + this.discount || 0} </strong><br>` +
					`Sử Dụng Tiền Tích Luỹ: <strong>${this.buyNowForm.accredit ? 'Có' : 'Không'}</strong><br>` +
					`<p style="color: red">Tổng Tiền: <strong>${this.buyNowForm.totalMoney}</strong></p></div>`,
				showCancelButton: true,
				showConfirmButton: true,
				cancelButtonText: 'Huỷ Bỏ',
				confirmButtonText: 'Chắc Rồi !',
				reverseButtons: true,
				focusConfirm: true,
			})
				.then((x) => {
					if (x.isConfirmed) {
						const apiUrl = apiBuilder('product/buyselfinputproduct.php');

						const param = {
							params: {
								productid: this.buyNowForm.period,
								username: this.username,
								token: this.token,
								voucher: this.buyNowForm.discount || '',
								isuseaccredited: this.buyNowForm.accredit ? '1' : '0',
								email: this.buyNowForm.username,
								password: this.buyNowForm.password,
							},
						};

						axios.post(apiUrl, null, param).then((res) => {
							// console.log(res.data)

							const error = res.data.error;

							if (error == '801') {
								sessionStorage.setItem('redirectPath', window.location.pathname);

								return this.$router.replace({
									name: 'Login',
								});
							} else if (error == '803') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Sản Phẩm Không Tồn Tại !',
								});
							} else if (error == '802') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Mã Tài Khoảng Phải Là Số !',
								});
							} else if (error == '804') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Sản Phẩm Nhập Không Hợp Lệ !',
								});
							} else if (error == '805') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Số Dư Không Đủ !',
								});
							} else if (error == '806') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Voucher Không Hợp Lệ !',
								});
							} else if (error != '800') {
								this.$swal({
									icon: 'error',
									title: 'Lỗi !',
									text: 'Server Error ! Please Reload',
								});
							} else {
								this.$swal({
									icon: 'success',
									title: 'Thành Công !',
									text: 'Đăng Ký Tài Khoản Thành Công !',
								});

								a.reset();
							}
						});
					}
				})
				.catch((e) => {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: e,
					});
				});
		}
	}

	created() {
		this.getPrice(this.$route.params.id);
	}
}
