




















































import { Vue, Component } from 'vue-property-decorator'
import axios from 'axios'
import { apiBuilder } from '../../config'
import { namespace } from 'vuex-class'
import BuyNowModal from '../../components/BuyNowModal.vue'

const auth = namespace('auth')

@Component({
	components: {
		BuyNowModal,
	},
})
export default class ProductDetail extends Vue {
	private apiProduct = 'product/getproductbyid.php'
	private product: Array<object> = []
	private loading = true

	private buyNowModal = false

	@auth.State
	public isLogined!: boolean
	@auth.State
	public username!: string

	public upperText(str: string): string {
		return str.toUpperCase()
	}

	public openBuyNowModal(): void {
		if (this.isLogined) {
			this.buyNowModal = true
		} else {
			sessionStorage.setItem('redirectPath', window.location.pathname)

			this.$router.replace({
				name: 'Login',
			})
		}
	}

	public getProductDetail(id: string): void {
		this.loading = true

		const param = {
			params: {
				productid: parseInt(id),
			},
		}

		const api = apiBuilder(this.apiProduct)

		axios
			.post(api, null, param)
			.then((res) => {
				const code = res.data.error

				// console.log(res.data.data[0])

				if (code == '772') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Không Tìm Thấy Sản Phẩm !',
					})
				}

				if (code == '776') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Please Reload !',
					})
				}

				this.product = res.data.data[0]
			})
			.catch((e) => {
				this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				})
			})

		setTimeout(() => {
			this.loading = false
		}, 500)
	}

	created() {
		this.getProductDetail(this.$route.params.id)
	}
}
