




























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { AccountShowInterface } from '../interfaces'

@Component
export default class AccountShowModal extends Vue {
	@Prop() readonly value!: boolean
	@Prop() readonly data!: AccountShowInterface

	private accountData = []

	// COMPUTED
	get dialog(): boolean {
		return this.value
	}

	set dialog(value) {
		this.$emit('input', value)
	}

	// METHOD
}
